import React, { useEffect, useState } from "react";
import "./orderDetails.css";
import "../../Style.css";
import { Link } from "react-router-dom";
import { loadingState } from "../../actions/userAction";
import { useDispatch } from "react-redux";

function OrderConfirmation({ history, location }) {
    const dispatch = useDispatch();
    const [dollar, setDollar] = useState("");
    async function VerifyOrder() {
        dispatch(loadingState(true));
        var res2 = await fetch(`/api/admin/marque`);
        let data2 = await res2.json();
        setDollar(data2[4].value);
        const params = new URLSearchParams(location.search);
        const order_id = params.get('order_id');
        const PayerID = params.get('PayerID');
        const paymentId = params.get('paymentId');
        const pay_type = params.get('pay_type');
        var payload = null;
        if (order_id && pay_type === "Razorpay") {
            getorders()
            return
        }
        if (order_id) {
            if (PayerID && paymentId) {
                payload = JSON.stringify({ order_id, PayerID, paymentId })
            } else {
                payload = JSON.stringify({ order_id })
            }
            setOrder_id(order_id)
            try {
                const response = await fetch('/api/checkout/payment/verify', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: payload, // Replace with the actual Payment Intent ID
                });

                if (response.ok) {
                    const data = await response.json();
                    getorders()
                } else {
                    dispatch(loadingState(false));
                    history.push("/")
                }
            } catch (error) {
                dispatch(loadingState(false));
                console.error('Error verifying payment:', error);
            }
        } else {
            dispatch(loadingState(false));
            history.push("/")
        }
    }


    useEffect(() => {
        VerifyOrder()
    }, []);

    const [orders, setOrders] = useState([]);
    const [order_Id, setOrder_id] = useState([]);
    const [address, setAddress] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);

    async function getorders() {
        try {
            const params = new URLSearchParams(location.search);
            const order_id = params.get('order_id');
            if (order_id) {
                var res = await fetch(`/api/admin/orders/${order_id}`);
                if (res.ok) {
                    let data = await res.json();
                    console.log("data", data)
                    setOrders(data.orders)
                    setAddress(data.shipping)
                    dispatch(loadingState(false));
                } else {
                    dispatch(loadingState(false));
                    let data = await res.json();
                    setOrders([])
                }
            }
        } catch (error) {
            dispatch(loadingState(false));
            console.error(error);
        }
    }


    const getTotalAmount = () => {
        return orders.reduce((total, row) => total + row.amount_charged, 0);
    };

    const getItemAmount = () => {
        if (orders.length)
            return orders.reduce((total, row) => total + row.selling_price, 0);
    };
    const getShippingAmount = () => {
        if (orders.length)
            return orders.reduce(
                (total, row) => Math.floor(total + (row.pay_type === "PayPal" ? row.amount_charged * dollar : row.amount_charged) - row.selling_price + orders[0].discount),
                0
            );
    };


    return (
        <>
            <div className="UserConfirmation p-bottom-50">
                <section className="py-2 order-completed">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-9">
                                <div className="thanks-screen text-center">

                                    <div className="success-animation">
                                        <div className="svg-box">
                                            <svg className="circular green-stroke">
                                                <circle className="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10"></circle>
                                            </svg>
                                            <svg className="checkmark green-stroke">
                                                <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                                                    <path className="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>

                                    <h2>
                                        Thank You</h2>
                                    <h3>
                                        Your order <Link to="#"><strong>#{order_Id}</strong></Link> has been placed!                            </h3>
                                    <p>
                                        We sent an email to <strong></strong> with your order confirmation and receipt. If the email hasn't arrived within two minutes, please check your spam folder to see if the email was routed there.

                                    </p>
                                    <p className="placed">
                                        <span>
                                            <strong><i className="fa-regular fa-clock"></i> Time Placed:</strong> {orders.length ? orders[0].modified : ""}
                                        </span>

                                        <span>
                                            {orders.length ? <a target="_blank" href={orders[0]["invoice"]} onclick="window.print();"> <i className="fa-solid fa-print"></i>Print</a> : ""}

                                        </span>
                                    </p>
                                </div>

                                <ul className="completed-detail">
                                    <li>
                                        <h4> Shipping Address </h4>
                                        <p> <strong>{address.first_name} {address.last_name}</strong>
                                            <br /> {address.address1} {address.address2}
                                            <br /> {address.city}, {address.state},
                                            <br /> {address.country}
                                            <br />{address.mobile}
                                        </p>

                                        <p></p>
                                    </li>
                                    <li>
                                        <h4> Billing Address </h4>
                                        <p> <strong>{address.first_name} {address.last_name}</strong>
                                            <br /> {address.address1} {address.address2}
                                            <br /> {address.city}, {address.state},
                                            <br /> {address.country}
                                            <br />{address.mobile}
                                        </p>

                                        <p></p>
                                    </li>

                                    <li>
                                        <h4>Payment Details </h4>
                                        <p> <strong>Payment Method : </strong> {orders.length ? orders[0].pay_type : ""}</p>
                                        <p> <strong>Payment Status : </strong>Paid </p>
                                    </li>
                                </ul>

                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div className="completed-cart">
                                            <div className="row justify-content-between">
                                                <div className="col-md-7">
                                                    <h5>Order List</h5>
                                                    <div className="completed-cart-list scroll-y">
                                                        <ul className="list-group list-cart list-cart-checkout">
                                                            {orders.length > 0 ? orders.map((order, index) => {
                                                                return <li className="list-group-item">
                                                                    <div className="product-profile">
                                                                        <div className="product-profile__thumbnail">
                                                                            <Link to="#">
                                                                                <img className="img-fluid" data-ratio="3:4" src={`${process.env.REACT_APP_BASE_URL}${order.image}`} alt="..." />
                                                                            </Link>
                                                                            <span className="product-qty">{order.quantity}</span>
                                                                        </div>
                                                                        <div className="product-profile__data">
                                                                            <div className="title"><a className="" href="#"> {order.name}</a></div>

                                                                            <p className="">{order.quantity}</p>

                                                                        </div>
                                                                    </div>

                                                                    <div className="product-price">Rs {order.selling_price}</div>

                                                                </li>
                                                            }) : <></>}

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="sticky-item">
                                                        <h5>Order Summary</h5>
                                                        <div className="cart-total mt-5">
                                                            <ul className="list-group list-group-flush-x list-group-flush-y">
                                                                <li className="list-group-item border-0">
                                                                    <span className="label">Subtotal</span> <span className="ml-auto">Rs {getItemAmount()}</span>
                                                                </li>
                                                                {/* <li className="list-group-item ">
                                                                    <span className="label">Tax</span> <span className="ml-auto">$89.90</span>
                                                                </li> */}

                                                                <li className="list-group-item ">
                                                                    <span className="label">Shipping</span> <span className="ml-auto">Rs {getShippingAmount()}</span>
                                                                </li>
                                                                <li className="list-group-item hightlighted">
                                                                    <span className="label">Total</span> <span className="ml-auto">{orders.length ? orders[0]["pay_type"] === "PayPal" ? "$" : "Rs" : "Rs"} {getTotalAmount()}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <Link to="#" className="contiShop">
                                                        <i className="fa-solid fa-arrow-left-long"></i> Continue
                                                        Shopping
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
export default OrderConfirmation;