import React from "react";
import playStore from "../../../images/playstore.png";
import appStore from "../../../images/Appstore.png";
// import "./Footer.css";
import Visa from "../../../images/visa.png";
import Whitelogo from "../../../images/whiteLogo.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="p-top-50">
      <div className="fixedWhatsapp"><a className="td-n" href="https://wa.me/+919872407145">
        <i className="fa-brands fa-whatsapp"></i>
      </a>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <div className="footcol">
              <h2 className="colTitle">Get In Touch</h2>
              <ul>
                <li>
                  <p className="smallTitle">
                    <i className="fa-solid fa-phone-volume"></i> Phone:
                  </p>
                  <div className="numbers">
                    <Link to="tel:+91 9872407145">+91 987240 7145</Link>
                  </div>
                </li>
                <li>
                  <p>
                    <i className="fa-solid fa-envelope"></i>Email :-{" "}
                    <Link to="mailto:info@vastrakala.com">
                      info@vastrakala.com
                    </Link>{" "}
                  </p>
                </li>
                <li>
                  <p>
                    <i className="fa-solid fa-clock"></i>Mon-Sat 10:00 am to
                    6:00 pm IST
                  </p>
                </li>
                <li>
                  <p>
                    <i className="fa-solid fa-location-dot"></i>VASTRAKALA Mandi
                    Road Gandhi Chowk, <br />
                    Phagwara, Punjab 144401
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="footcol">
              <h2 className="colTitle">Our Shop</h2>
              <ul>
                <li>
                  <Link to="/category/Anarkali%20Suit/337">Anarkali suit</Link>
                </li>
                <li>
                  <Link to="/category/Bridal%20Lehengas/414">Bridal Lehnga</Link>
                </li>
                <li>
                  <Link to="/category/Sharara%20Suit/336">Sharara Suit</Link>
                </li>
                <li>
                  <Link to="/category/Garara%20Suit/340">Garara Suit</Link>
                </li>
                <li>
                  <Link to="/category/Bridesmaid%20Lehenga/470">Lehenga</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-2">
            <div className="footcol">
              <h2 className="colTitle">Information</h2>
              <ul>
                <li>
                  <Link to="/About">About Us</Link>
                </li>
                <li>
                  <Link to="/Contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="#">Order Tracking</Link>
                </li>
                <li>
                  <Link to="../../UserAccount">Your Account</Link>
                </li>
                <li>
                  <Link to="/Return">Return/Exchange Policy</Link>
                </li>
                <li>
                  <Link to="/Shipping">Shipping Policy</Link>
                </li>
                <li>
                  <Link to="/FAQ">FAQ's</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="footcol">
              <h2 className="colTitle">Safe & Secure Payment</h2>
              <img src={Visa} width="100%" />
              <h2 className="colTitle mt-2">Follow Us</h2>
              <ul className="social">
                {/* <li>
                  <Link to="#">
                    <i className="fa-brands fa-facebook-f"></i>
                  </Link>
                </li> */}
                <li>
                  <a
                    href="https://www.instagram.com/vastrakala.boutique/?hl=en"
                    target="_blank"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@VastraKala45"
                    target="_blank"
                  >
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a href="https://wa.me/+919872407145" target="_blank">
                    <i className="fa-brands fa-whatsapp"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-12">
            <hr />
          </div>
        </div>
        <div className="row copyright">
          <div className="col-md-6">
            <div className="footLogo">
              <img src={Whitelogo} />
              <p>© 2024 Vastra Kala. All Rights Reserved.</p>
            </div>
          </div>
          <div className="col-md-6">
            <ul className="footMenu">
              <li>
                <Link to="/PrivacyPolicy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/TermsOfService">Terms of Service</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
